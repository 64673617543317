/** ***************************************************************************
 * Templates / Error
 *************************************************************************** */

body {

  --sm-c-page--tn--contentwidth: auto;

  /**
   * Main content
   */

  --sm-c-page__main--TextAlign: center;
  --sm-c-page__main--sm-md--TextAlign: left;

  --sm-c-page__main--JustifyItems: center;
  --sm-c-page__main--sm-md--JustifyItems: start;


  /**
   * Heading
   */

  --sm-c-page__heading--Font: var(--sm-global--Font-title-lg-semibold);
  --sm-c-page__heading--sm-md--Font: var(--sm-global--Font-title-xl-semibold);

  --sm-c-page__heading--MarginBlockEnd: .27em;  /* 12px / 19px */


  /**
   * Text
   */

  --sm-c-page__text--Font: var(--sm-global--Font-label-xl-medium);
  --sm-c-page__text--sm-md--Font: var(--sm-global--Font-paragraph-sm-medium);


  /**
   * Back button
   */

  --sm-c-page__back--MarginBlockStart: 1.19rem;  /* 19px */
  --sm-c-page__back--sm-md--MarginBlockStart: 2.5rem;  /* 40px */
}

/** Block: small / Inline: medium */
@media all and (min-height: 31.25rem) and (min-width: 48rem) {
  body {
    --sm-c-page__back--MarginBlockStart: var(--sm-c-page__back--sm-md--MarginBlockStart);
    --sm-c-page__heading--Font: var(--sm-c-page__heading--sm-md--Font);
    --sm-c-page__main--JustifyItems: var(--sm-c-page__main--sm-md--JustifyItems);
    --sm-c-page__main--TextAlign: var(--sm-c-page__main--sm-md--TextAlign);
    --sm-c-page__text--sm-md--Font: var(--sm-c-page__text--sm-md--Font);
  }
}



/**
 * Main content
 */

main {
  --sm-c-page__main--GridAutoColumns: auto;
  --sm-c-page__main--GridAutoFlow: row;
  align-self: center;
  justify-items: var(--sm-c-page__main--JustifyItems);
  text-align: var(--sm-c-page__main--TextAlign);
}



/**
 * Heading
 */

main > h1 {
  font: var(--sm-c-page__heading--Font);
  line-height: 1;
  margin-block: 0 var(--sm-c-page__heading--MarginBlockEnd);
}

@supports not (margin-block: 0) {
  main > h1 {
    margin-bottom: var(--sm-c-page__heading--MarginBlockEnd);
    margin-top: 0;
  }
}



/**
 * Text
 * 1: reset
 */

main > p {
  font: var(--sm-c-page__text--Font);
  margin: 0;  /* 1 */
}



/**
 * Back button
 */

main > .sm-c-button {
  --sm-c-button--BackgroundColor: var(--sm-c-page--BackgroundColor);
  align-self: center;
  margin-block-start: var(--sm-c-page__back--MarginBlockStart);
}

@supports not (margin-block-start: 0) {
  main > .sm-c-button {
    margin-top: var(--sm-c-page__back--MarginBlockStart);
  }
}



/**
 * Screenshot button
 */

.sm-c-nav .sm-c-button-black.sm-m-capture {
  display: none;
}
